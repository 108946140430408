<template>
  <div class="main">
    <div class="back" @click="topath('/index')">
      <div>分会管理</div>
      <div>
        <img
          src="https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E4%B8%89%E8%A7%92%E5%BD%A2.svg"
          alt=""
        />
      </div>
      <div>分会活动表</div>
      <div>
        <img
          src="https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E4%B8%89%E8%A7%92%E5%BD%A2.svg"
          alt=""
        />
      </div>
    </div>
    <div class="thetitle">分会活动表</div>
    <div class="profile">
      <div class="title">
        <div class="infor">活动列表</div>
        <div>
          <a-popconfirm
            placement="bottom"
            ok-text="确定"
            cancel-text="取消"
            @confirm="confirmtwo"
          >
            <template #title>
              <div class="select">
                <a-checkbox v-model:checked="select0" @change="handupdate()" />
                <div class="selecttype">2023年度</div>
              </div>
              <div class="select">
                <a-checkbox v-model:checked="select1" @change="handupdate()" />
                <div class="selecttype">2022年度</div>
              </div>
              <div class="select">
                <a-checkbox v-model:checked="select2" @change="handupdate()" />
                <div class="selecttype">2021年度</div>
              </div>

              <div class="select">
                <a-checkbox v-model:checked="select3" @change="handupdate()" />
                <div class="selecttype">2020年度</div>
              </div>
              <!-- <p>确认取消此会员的资格吗?</p> -->
              <!-- <p>{{ text }}</p> -->
            </template>
            <div class="screen hoverbottom">往年活动</div>
          </a-popconfirm>
        </div>
      </div>

      <div class="table">
        <a-table
          :columns="columns"
          :data-source="data"
          :pagination="pagination"
          @change="change"
        >
          <template #name="{ text }">
            <div class="table-cell">
              <div>{{ text }}</div>
              <!-- <i class="icon-class hand" @click="handleIconClick(record)"></i> -->
            </div>
          </template>
          <template #checkbox="{ record }">
            <div class="table-cell">
              <div
                class="look"
                @click="look(record.id, record.created_at.slice(0, 4))"
              >
                查看
              </div>
            </div>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
</style>

<script>
import { activitylist } from "../api/twice";
export default {
  name: "Home",
  data() {
    return {
      pagination: {
        current: 1, // 当前页码
        pageSize: 3, // 每页显示条目数
      },
      select1: false,
      select2: false,
      select3: false,
      select0: true,
      visibler: false,
      confirmVisible: false,
      lastname: {
        name: "",
        email: "",
        unit: "",
      },
      columns: [
        {
          title: "活动名称",
          dataIndex: "title",
          key: "title",
          slots: { customRender: "title" },
          width: 130,
        },
        {
          title: "出席人数",
          dataIndex: "attendance",
          key: "attendance",
          slots: { customRender: "attendance" },
          //   width: 80,
        },
        {
          title: "普通门票费用",
          dataIndex: "admission_fee",
          key: "admission_fee",
          ellipsis: true,
        },
        {
          title: "工会开销",
          dataIndex: "chapter_expenditure",
          key: "chapter_expenditure",
          ellipsis: true,
        },
        {
          title: "邮箱",
          dataIndex: "contact_email",
          key: "contact_email",
          ellipsis: true,
        },
        {
          title: "开始时间",
          dataIndex: "zh_start_time",
          key: "zh_start_time",
          ellipsis: true,
        },
        {
          title: "结束时间",
          dataIndex: "zh_end_time",
          key: "zh_end_time",
          ellipsis: true,
        },
        {
          title: "操作",
          dataIndex: "name",
          key: "name",
          selected: false,
          slots: { customRender: "checkbox" },
        },
      ],
      data: [
        {
          index: "1",
          key: "青岛影视设计周",
          name: "50000",
          email: "0",
          unit: "0",
          age: 32,
          address: "New York No. 1 Lake Park, New York No. 1 Lake Park",
          type: "概况",
          tags: ["nice", "developer"],
          add_time: "2021年3月18日",
          expiration_time: "2023年3月18日",
          selected: false,
        },
        {
          index: "2",
          key: "长三角数字媒体教育论坛暨中国杯3D数字游戏艺术大赛",
          name: "50000",
          unit: "0",
          age: 42,
          email: "0",
          type: "概况",
          address: "London No. 2 Lake Park, London No. 2 Lake Park",
          tags: ["loser"],
          add_time: "2021年3月18日",
          expiration_time: "2023年3月18日",
          selected: false,
        },
        {
          index: "3",
          key: "青岛影视设计周",
          name: "50000",
          email: "0",
          unit: "0",
          age: 32,
          type: "概况",
          address: "Sidney No. 1 Lake Park, Sidney No. 1 Lake Park",
          tags: ["cool", "teacher"],
          add_time: "2021年3月18日",
          expiration_time: "2023年3月18日",
          selected: false,
        },
        {
          index: "4",
          key: "长三角数字媒体教育论坛暨中国杯3D数字游戏艺术大赛",
          name: "100",
          type: "概况",
          email: "0",
          unit: "0",
          age: 32,
          address: "New York No. 1 Lake Park, New York No. 1 Lake Park",
          tags: ["nice", "developer"],
          add_time: "2021年3月18日",
          expiration_time: "2023年3月18日",
          selected: false,
        },
        {
          index: "5",
          key: "长三角数字媒体教育论坛暨中国杯3D数字游戏艺术大赛",
          name: "100",
          type: "概况",
          email: "0",
          unit: "0",
          age: 42,
          address: "London No. 2 Lake Park, London No. 2 Lake Park",
          tags: ["loser"],
          add_time: "2021年3月18日",
          expiration_time: "2023年3月18日",
          selected: false,
        },
        {
          index: "6",
          key: "长三角数字媒体教育论坛暨中国杯3D数字游戏艺术大赛",
          name: "100",
          type: "概况",
          unit: "0",
          email: "0",
          age: 32,
          address: "Sidney No. 1 Lake Park, Sidney No. 1 Lake Park",
          tags: ["cool", "teacher"],
          add_time: "2021年3月18日",
          expiration_time: "2023年3月18日",
          selected: false,
        },
      ],
    };
  },
  components: {},
  methods: {
    async gethotlist(e) {
      const data = await activitylist({
        perPage: "1000",
        page: "1",
        condition: [...e],
      });
      this.data = data.data.data.map((a, v) => {
        // console.log(a, i, v);
        const zh_end_time =
          a.end_time.slice(0, 4) +
          "年" +
          a.end_time.slice(5, 7) +
          "月" +
          a.end_time.slice(8, 10) +
          "日";
        const zh_start_time =
          a.start_time.slice(0, 4) +
          "年" +
          a.start_time.slice(5, 7) +
          "月" +
          a.start_time.slice(8, 10) +
          "日";
        return {
          selected: false,
          zh_end_time,
          zh_start_time,
          ...a,
          index: v,
        };
      });
      console.log(data);
    },
    change(p, f, s) {
      this.pagination = p;
      console.log(p, f, s);
    },
    look(e, year) {
      // console.log(e, year, 12345);
      this.$router.push({ path: "/addactivity", query: { id: e, year: year } });
    },
    topath(e) {
      this.$router.push(e);
    },
    handleConfirm() {
      // Perform your confirm action here
      //   console.log("Confirmed selection:", this.selectedRecords);
      //   this.confirmVisible = false;
    },
    confirm(e) {
      console.log(e);
      this.confirmVisible = true;
    },
    conery(e) {
      this.data[e - 1].selected = false;
    },
    coner(e) {
      console.log(e, 122335);
      this.data[e - 1].selected = true;
    },

    confirmtwo() {
      const du = [];
      if (this.select0) {
        du.push("2023");
      }
      if (this.select1) {
        du.push("2022");
      }
      if (this.select2) {
        du.push("2021");
      }
      if (this.select3) {
        du.push("2020");
      }
      this.gethotlist(du);
      // console.log(
      //   122335,
      //   this.select0,
      //   this.select1,
      //   this.select2,
      //   this.select3
      // );
      //   console.log(e);
      //   this.confirmVisible = true;
    },
    conerytwo() {
      console.log(123);
      //   this.data[e - 1].selected = false;
    },
    conertwo() {
      //   console.log(e, 122335);
      //   this.data[e - 1].selected = true;
      //   console.log(e);
    },
    handleCancel() {
      //   this.confirmVisible = false;
    },

    handleIconClick(record) {
      console.log(record, 9999);
      this.visibler = true;
      //   console.log(this.visible, 98765);
      // Handle icon click for the specific record
    },
    handupdate(e) {
      this.confirmVisible = true;

      console.log(1234566, e, this.confirmVisible);
    },
    handleOk(e) {
      console.log(e, 123456);
    },
  },
  mounted() {
    this.gethotlist([2023]);
  },
};
</script>
  <style lang="scss">
.buttom {
  .ant-popover-inner {
    width: 209px !important;
  }
}
.select {
  display: flex;
  margin-bottom: 12px;
  .selecttype {
    margin-left: 10px;
  }
}
// .ant-checkbox-checked:after
.ant-checkbox {
  &:hover {
    border-color: #c10a74 !important;
  }
  &::after {
    border-color: #c10a74 !important;
  }
}
.ant-checkbox-checked .ant-checkbox-inner {
  background: #d15243 !important;
  border-color: #d15243 !important;
}
.ant-popover-inner {
  padding: 24px 24px 24px 16px !important;
  .ant-popconfirm-message-icon {
    display: none !important;
  }
  .ant-popconfirm-buttons {
    display: flex;
    justify-content: space-between;
    .ant-btn-default {
      width: 68px;
      height: 32px;
      border-radius: 20px 20px 20px 20px;
      opacity: 1;
      border: 1px solid #d15243;
      font-size: 12px;
      font-weight: 400;
      color: #d15243;
      line-height: 14px;
      &:hover {
        opacity: 0.6;
      }
    }
    .ant-btn-primary {
      width: 68px;
      height: 32px;
      background: #d15243;
      border-radius: 20px 20px 20px 20px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 14px;
      &:hover {
        opacity: 0.6;
      }
    }
  }
}
// .el-input__wrapper {
//   height: 38px;
//   box-sizing: border-box;
//   padding: 0 10px !important;
//   border: 2px solid #777777 !important;
//   border-radius: 8px !important;
// }
.ant-modal-footer {
  display: none;
}
.ant-modal-content {
  width: 378px;
}
.ant-table-cell {
  padding: 28px 15px !important;
  text-align: center;
}
.ant-table-thead {
  background: #f8e7e5 !important;

  .ant-table-cell {
    text-align: center !important;
  }

  th {
    background: #f8e7e5 !important;
  }
}
</style>
  <style scoped lang="scss">
.profile {
  .title {
    display: flex;
    justify-content: space-between;
  }
  .screen {
    width: 130px;
    background: #c81677;
    height: 38px;
    border-radius: 40px 40px 40px 40px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 38px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
      content: "";
      display: inline-block;
      width: 18px; /* 设置图片的宽度 */
      height: 18px; /* 设置图片的高度 */
      background-image: url("https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E4%B8%8B%E6%8B%89.svg"); /* 替换为您的图片 URL */
      background-size: cover; /* 调整图片的显示方式 */
      margin-left: 6px; /* 调整图片与 div 之间的间距 */
      // margin-right: 6px;
      // transform: rotate(180deg);
    }
  }
}
.table-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  .look {
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    color: #c81677;
    line-height: 19px;
    &:hover {
      text-decoration: underline;
    }
  }
}

.main {
  padding: 100px 0px;
  max-width: 1264px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .profile {
    padding: 56px 60px;
    // border: 2px solid #333333;
    border-radius: 40px 0 0 0;
    width: 100%;
    box-sizing: border-box;
    color: #333;
    .hintoperation {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .hint {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        :nth-child(2) {
          margin-left: 28px;
          margin-top: 4px;
        }
      }
      .buttom {
        display: flex;
        .add {
          width: 102px;
          height: 38px;
          border-radius: 40px 40px 40px 40px;
          border: 1px solid #c81677;
          font-size: 14px;
          font-weight: 400;
          color: #c81677;
          line-height: 38px;
          text-align: center;
          margin-right: 40px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          &::before {
            content: "";
            display: inline-block;
            width: 18px; /* 设置图片的宽度 */
            height: 18px; /* 设置图片的高度 */
            background-image: url("https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E5%8A%A0.svg"); /* 替换为您的图片 URL */
            background-size: cover; /* 调整图片的显示方式 */
            // margin-left: 6px; /* 调整图片与 div 之间的间距 */
            margin-right: 6px;
            // transform: rotate(180deg);
          }
        }
      }
    }

    .amount {
      display: flex;
      margin-bottom: 20px;
      :nth-child(1) {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
      }
      :nth-child(2) {
        font-size: 18px;
        font-weight: bold;
        color: #c10974;
      }
    }
    .save {
      width: 364px;
      margin: 0 auto;
      text-align: center;
      height: 58px;
      background: #c81677;
      border-radius: 50px 50px 50px 50px;
      line-height: 58px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      margin-top: 60px;

      cursor: pointer;
    }

    .row {
      display: flex;
      margin-bottom: 20px;
      align-items: center;

      .nation {
        display: flex;
        align-items: center;
        flex: 1;
      }
      .city {
        display: flex;
        align-items: center;
        flex: 1;
      }
      //   :nth-child(1) {
      //     font-size: 18px;
      //     font-weight: bold;
      //     color: #333333;
      //     line-height: 21px;
      //     width: 126px;
      //     text-align: right;
      //   }
      .titler {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 21px;
        width: 163px;
        text-align: right;
      }
      .left20 {
        margin-left: 20px;
      }
      //   :nth-child(2) {
      //     margin-left: 20px;
      //   }
      .input {
        margin-left: 20px;
        // margin-bottom: 40px;
        // width: 100%;
        flex: 1;
        position: relative;
        // .hint {
        //   font-size: 12px;
        //   font-weight: 400;
        //   color: #333333;
        //   line-height: 14px;
        //   margin-top: 4px;
        //   position: absolute;
        //   margin-bottom: 20px;
        //   margin-left: 20px;
        .hintsize {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 16px;
          :nth-child(2) {
            margin-top: 4px;
          }
        }
        // }
      }
    }
    .margin40 {
      margin-bottom: 40px;
    }
    .theaddress {
      margin-top: 40px;
    }
    .title {
      font-size: 24px;
      font-weight: bold;
      color: #000000;
      line-height: 28px;
      position: relative;
      margin-bottom: 32px;
      .infor {
        display: flex;
        align-items: center;
        &::before {
          content: "";
          width: 6px;
          height: 24px;
          background: #c81677;
          position: absolute;
          left: -10px;
        }
      }
    }
  }
  .back {
    position: absolute;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
    top: 20px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      width: 17px;
      transform: rotate(180deg);
      margin: 0 6px;
    }
    div:hover {
      text-decoration: underline;
      color: #c81677;
    }
  }
}
</style>
          